import React from 'react';
import { useState, useEffect } from 'react';
import { EmptyLayout, LayoutRoute, MainLayout, PrivateRoute } from 'components/Layout';
import AuthPage from 'pages/AuthPage';
import DashboardPage from 'pages/DashboardPage';
import DashboardKPI from 'pages/DashboardKPI';
import AnalysisDetailsPage from 'pages/AnalysisDetailsPage';
import DashboardFilteredAndGrouped from 'pages/DashboardFilteredAndGrouped';
import YoutubeImports from 'pages/YoutubeImports';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';

import {
  http,
  // getCurrentUserURL,
  getSubscriptionTrialvsPaidURL,
  getFlagsPerDayURL,
  getSongsPerDayURL,
  getAnalyticsEventsPerDayURL,
  getUsersPerDayURL,
  getSubscribersPerDayURL,
  getTopYoutubeImportsURL,
  getFailedYoutubeImportsURL,
  getUsersPerMonthURL,
} from 'servicies/http';
import Logout from './servicies/logout';

const App = props => {
  const initialDataPerDayState = {
    loading: true,
    data: []
  };
  const [isCurrentUserFetched, setIsCurrentUserFetched] = useState(false);
  const [subscriptionsTrialvsPaid, setSubscriptionsTrialvsPaid] = useState({
    loading: true,
    subscriptionNames: [],
    dataTrial: [],
    dataPaid: []
  });
  const [flagsPerDay, setFlagsPerDay] = useState(initialDataPerDayState);
  const [songsPerDay, setSongsPerDay] = useState(initialDataPerDayState);
  const [analyticsEventsPerDay, setAnalyticsEventsPerDay] = useState(initialDataPerDayState);
  const [usersPerDay, setUsersPerDay] = useState(initialDataPerDayState);
  const [subscribersPerDay, setSubscribersPerDay] = useState(initialDataPerDayState);
  const [topYoutubeImports, setTopYoutubeImports] = useState({
    loading: true,
    data: []
  });
  const [failedYoutubeImports, setFailedYoutubeImports] = useState({
    loading: true,
    data: []
  });
  const [usersPerMonth, setUsersPerMonth] = useState({
    loading: true,
    data: []
  });

  const [errors, setErrors] = useState({
    subscriptionsTrialvsPaid: '',
    flagsPerDay: '',
    songsPerDay: '',
    analyticsEventsPerDay: '',
    usersPerDay: '',
    subscribersPerDay: '',
    topYoutubeImports: '',
    failedYoutubeImports: '',
    usersPerMonth: '',
  });

  const analyticsData = {
    subscriptionsTrialvsPaid,
    flagsPerDay,
    songsPerDay,
    analyticsEventsPerDay,
    usersPerDay,
    subscribersPerDay,
    usersPerMonth
  }

  const youtubeData = {
    topYoutubeImports,
    failedYoutubeImports
  }

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());  // remove time from date

  const oneYearAgo = new Date(now.getFullYear()-1, now.getMonth(), 1);
  const daysSinceOneYearAgo = Math.round((today - oneYearAgo) / (1000 * 60 * 60 * 24));

  const longAgo = new Date('2013-01-01');
  const daysSinceLongAgo = Math.round((today - longAgo) / (1000 * 60 * 60 * 24));

  // const lastDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0);
  // const oneYearAgoKPI = new Date(now.getFullYear() - 1, now.getMonth() - 1);
  // const daysSinceOneYearAgoKPI = Math.round((lastDayOfPreviousMonth - oneYearAgoKPI) / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const fetchCurrentUserAsync = async () => {
      try {
        // const { data: user } = await http.get(getCurrentUserURL());
        setIsCurrentUserFetched(true);
        // localStorage.setItem('user', JSON.stringify(user));
      } catch (e) {
        localStorage.removeItem('user');
      }
    };
    fetchCurrentUserAsync();
  }, []);

  useEffect(() => {
    const getSubscriptionsTrialvsPaid = async () => {
      try {
        const { data: subscriptions } = await http.get(getSubscriptionTrialvsPaidURL());
        const subscriptionNames = subscriptions
          .map(subscription => subscription.subscription_name)
          .filter((v, i, a) => a.indexOf(v) === i);

        let dataTrial = [];
        let dataPaid = [];

        subscriptionNames.forEach(subscriptionName => {
          const dataTrialCount = subscriptions
          .filter(subscription => subscription.subscription_name === subscriptionName && subscription.is_trial)
          .map(subscription => subscription.count);
          const dataPaidCount = subscriptions
          .filter(subscription => subscription.subscription_name === subscriptionName && !subscription.is_trial)
          .reduce((prev,current) => prev + parseInt(current.count), 0)

          dataTrial = [...dataTrial, ...dataTrialCount];
          dataPaid = [...dataPaid, dataPaidCount];
        });

        setSubscriptionsTrialvsPaid({
          loading: false,
          subscriptionNames,
          dataTrial,
          dataPaid
        });
      } catch (e) {
        setSubscriptionsTrialvsPaid({
          loading: false,
          subscriptionNames: [],
          dataTrial: [],
          dataPaid: []
        });
        setErrors(prev => ({
          ...prev,
          subscriptionsTrialvsPaid: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      }
    }
    if (isCurrentUserFetched) getSubscriptionsTrialvsPaid();
  },[isCurrentUserFetched]);

  useEffect(() => {
    const getFlagsPerDay = async() => {
      try {
        const { data } = await http.get(getFlagsPerDayURL());

        const dataAdjusted = data.map(flags => {
          // fix paid flags for 18 Oct 2022.
          if (flags.creation_date === '1666051200') {
            return ({
              ...flags,
              subscription_paid_pro: '2',
              subscription_paid_plus: '4',
              subscription_paid_songwriter: '0'
            })
          }
          // fix my scorecloud login flags from bots Dec 2022 - Jan 2023, the correct amount averages to about 50
          if (flags.creation_date >= '1670799600' && flags.creation_date <= '1673218800') {
            return ({
              ...flags,
              login_my_scorecloud: '50'
            })
          }

          return flags;
        });

        setFlagsPerDay({
          loading: false,
          data: dataAdjusted.map(item => ({
            ...item,
            creation_date: item.creation_date * 1000
          }))
        });
      } catch(e) {
        setFlagsPerDay({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          flagsPerDay: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      };
    }
    if (isCurrentUserFetched) getFlagsPerDay();
  },[isCurrentUserFetched]);

  useEffect(() => {
    const getSongsPerDay = async() => {
      try {
        const { data } = await http.get(getSongsPerDayURL());
        setSongsPerDay({
          loading: false,
          data: data.map(item => ({
            ...item,
            creation_date: item.creation_date * 1000
          }))
        });
      } catch(e) {
        setSongsPerDay({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          songsPerDay: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      };
    }
    if (isCurrentUserFetched) getSongsPerDay();
  },[isCurrentUserFetched]);

  useEffect(() => {
    const getAnalyticsEventsPerDay = async() => {
      try {
        const { data } = await http.get(getAnalyticsEventsPerDayURL());

        const dataAdjusted = data.map(items => {
          // fix subscription_charged_successfully lost due to server update around 2023-10-26 - 2023-11-03
          if (items.creation_date >= '1698278400' && items.creation_date <= '1698969600' ) {
            return ({
              ...items,
              subscription_charged_successfully: '43',
              subscription_charged_successfully_sum: '477',
              subscription_charged_successfully_plus_sum: '151',
              subscription_charged_successfully_songwriter_sum: '76',
              subscription_charged_successfully_pro_sum: '251',
            })
          }

          // fix missing video_import data due to analytics events not implemented yet in Songwriter client
          if (items.creation_date >= '1702944000' && items.creation_date <= '1703635200' ) {
            return ({
              ...items,
              video_import: '38'
            })
          }

          return items;
        });

        setAnalyticsEventsPerDay({
          loading: false,
          data: dataAdjusted.map(item => ({
            ...item,
            creation_date: item.creation_date * 1000
          }))
        });
      } catch(e) {
        setAnalyticsEventsPerDay({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          analyticsEventsPerDay: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      };
    }
    if (isCurrentUserFetched) getAnalyticsEventsPerDay();
  }, [isCurrentUserFetched]);

  useEffect(() => {
    const getUsersPerDay = async() => {
      try {
        const { data } = await http.get(getUsersPerDayURL());
        setUsersPerDay({
          loading: false,
          data: data.map(item => ({
            ...item,
            creation_date: item.registration_date * 1000
          }))
        });
      } catch(e) {
        setUsersPerDay({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          usersPerDay: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      };
    }
    if (isCurrentUserFetched) getUsersPerDay();
  }, [isCurrentUserFetched]);

  useEffect(() => {
    const getSubscribersPerDay = async() => {
      try {
        const { data } = await http.get(getSubscribersPerDayURL());
        setSubscribersPerDay({
          loading: false,
          data: data.map(item => ({
            ...item,
            creation_date: item.date_created * 1000
          }))
        });
      } catch(e) {
        setSubscribersPerDay({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          subscribersPerDay: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      };
    }
    if (isCurrentUserFetched) getSubscribersPerDay();
  }, [isCurrentUserFetched]);

  useEffect(() => {
    const getTopYoutubeImports = async () => {
      try {
        const { data } = await http.get(getTopYoutubeImportsURL());

        setTopYoutubeImports({
          loading: false,
          data,
        });
      } catch (e) {
        setTopYoutubeImports({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          topYoutubeImports: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      }
    }
    if (isCurrentUserFetched) getTopYoutubeImports();
  },[isCurrentUserFetched]);

  useEffect(() => {
    const getFailedYoutubeImports = async () => {
      try {
        const { data } = await http.get(getFailedYoutubeImportsURL());

        setFailedYoutubeImports({
          loading: false,
          data,
        });
      } catch (e) {
        setFailedYoutubeImports({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          failedYoutubeImports: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      }
    }
    if (isCurrentUserFetched) getFailedYoutubeImports();
  },[isCurrentUserFetched]);

  useEffect(() => {
    const getUsersPerMonth = async () => {
      try {
        const { data } = await http.get(getUsersPerMonthURL());

        setUsersPerMonth({
          loading: false,
          data: data.map(item => ({
            ...item,
            creation_date: item.creation_date * 1000
          }))
        });
      } catch (e) {
        setUsersPerMonth({ loading: false, data: [] });
        setErrors(prev => ({
          ...prev,
          failedYoutubeImports: 'Could not read data, perhaps session timed out. Try logging out and logging in again.'
        }));
      }
    }
    if (isCurrentUserFetched) getUsersPerMonth();
  },[isCurrentUserFetched]);

  let username;
  let mainLayout;
  try {
    username = JSON.parse(localStorage.getItem('user'))['username'];
  } catch (e) {
    console.error('No user in session.')
  }
  if (username && username === 'stakeholder') {
    mainLayout =
    <>
      <PrivateRoute exact path="/" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardKPI {...props} title={'Last year'} analyticsData={analyticsData} errors={errors}/>
      )}/>
    </>
  } else {
    mainLayout = 
    <>
      <PrivateRoute exact path="/" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardPage {...props} title={'Last 6 months'} analyticsData={analyticsData} errors={errors}/>
      )}/>
      <PrivateRoute exact path="/analysis-details" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <AnalysisDetailsPage {...props} analyticsData={analyticsData} errors={errors}/>
      )}/>
      <PrivateRoute exact path="/data/daily" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardFilteredAndGrouped {...props} title={'Last 90 days'} analyticsData={analyticsData} errors={errors} filter={90}/>
      )}/>
      <PrivateRoute exact path="/data/weekly" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardFilteredAndGrouped {...props} title={'Last year'} analyticsData={analyticsData} errors={errors} filter={daysSinceOneYearAgo} group={'week'}/>
      )}/>
      <PrivateRoute exact path="/data/monthly" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardFilteredAndGrouped {...props} title={'Last year'} analyticsData={analyticsData} errors={errors} filter={daysSinceOneYearAgo} group={'month'}/>
      )}/>
      <PrivateRoute exact path="/data/all-time" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardFilteredAndGrouped {...props} title={'All time'} analyticsData={analyticsData} errors={errors} filter={daysSinceLongAgo} group={'month'}/>
      )}/>
      <PrivateRoute exact path="/kpi" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <DashboardKPI {...props} title={'Last year'} analyticsData={analyticsData} errors={errors}/>
      )}/>
      <PrivateRoute exact path="/youtube-imports" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
        <YoutubeImports {...props} youtubeData={youtubeData} errors={errors}/>
      )}/>
    </>
  }

  return (
    <BrowserRouter>
      <Switch>
        <LayoutRoute
          exact
          path="/login"
          layout={EmptyLayout}
          component={props => (
            <AuthPage {...props} isCurrentUserFetched={isCurrentUserFetched} setIsCurrentUserFetched={setIsCurrentUserFetched}/>
          )}
        />
        <PrivateRoute exact path="/logout" isCurrentUserFetched={isCurrentUserFetched} render={(props) => (
          <Logout />
        )}/>
        <MainLayout {...props} isCurrentUserFetched={isCurrentUserFetched} setIsCurrentUserFetched={setIsCurrentUserFetched} breakpoint={props.breakpoint}>
          {mainLayout}
        </MainLayout>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
