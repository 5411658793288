import React from 'react';
import { Col, Row, } from 'reactstrap';

import Page from 'components/Page';
import ChartCard from 'components/Card/ChartCard';
import { Line } from 'react-chartjs-2';
import { setOptions, chartConfig } from 'utils/chartConfigs';
import { colors } from 'utils/colors';
import {
  getDataSet,
  getSumDataSet,
  getFilteredData,
  getFilteredLabels,
  setDatesAsWeeks,
  setDatesAsMonths,
  getDataSetGrouped,
  groupBy,
  getDataSetGroupedAvg
} from 'utils/dataManager';

const SONGS_COUNT_CHART_LABEL = 'Songs';
const SUBSCRIPTION_COUNT_CHART_LABEL = 'Subscriptions';
const APP_STARTS_COUNT_CHART_LABEL = 'App starts';
const STACKED = true;
const FILL = true;
const CUSTOMHEIGHT = true;

const DashboardFilteredAndGrouped = ({ title, analyticsData, errors, filter, group }) => {
  const { flagsPerDay, songsPerDay, analyticsEventsPerDay, usersPerDay, subscribersPerDay } = analyticsData;

  const flagsFiltered = getFilteredData(flagsPerDay, filter);
  const songsFiltered = getFilteredData(songsPerDay, filter);
  const analyticsEventsFiltered = getFilteredData(analyticsEventsPerDay, filter);
  const usersFiltered = getFilteredData(usersPerDay, filter);
  const subscribersFiltered = getFilteredData(subscribersPerDay, filter);

  let flagsGrouped = [];
  let songsGrouped = [];
  let analyticsEventsGrouped = [];
  let usersGrouped = [];
  let subscribersGrouped = [];

  switch (group) {
    case 'week' :
      flagsGrouped = groupBy(setDatesAsWeeks(flagsFiltered, filter), 'creation_date');
      songsGrouped = groupBy(setDatesAsWeeks(songsFiltered, filter), 'creation_date');
      analyticsEventsGrouped = groupBy(setDatesAsWeeks(analyticsEventsFiltered, filter), 'creation_date');
      usersGrouped = groupBy(setDatesAsWeeks(usersFiltered, filter), 'creation_date');
      console.log('usersFiltered', usersFiltered);
      console.log('usersGrouped', usersGrouped);
      subscribersGrouped = groupBy(setDatesAsWeeks(subscribersFiltered, filter), 'creation_date');
      console.log('subscribersFiltered', subscribersFiltered);
      console.log('subscribersGrouped', subscribersGrouped);
      break;
    case 'month' :
      flagsGrouped = groupBy(setDatesAsMonths(flagsFiltered), 'creation_date');
      songsGrouped = groupBy(setDatesAsMonths(songsFiltered), 'creation_date');
      analyticsEventsGrouped = groupBy(setDatesAsMonths(analyticsEventsFiltered), 'creation_date');
      usersGrouped = groupBy(setDatesAsMonths(usersFiltered), 'creation_date');
      subscribersGrouped = groupBy(setDatesAsMonths(subscribersFiltered), 'creation_date');
      break;
    default:
      break;
  }

  const getDataSetFromFiltered = () => {
    return {
      flagsLabels: getFilteredLabels(flagsFiltered),
      songsLabels: getFilteredLabels(songsFiltered),
      analyticsEventsLabels: getFilteredLabels(analyticsEventsFiltered),
      usersLabels: getFilteredLabels(usersFiltered),
      subscribersLabels: getFilteredLabels(subscribersFiltered),
      newSongwriterSongs: getDataSet(songsFiltered, 'appname_songwriter'),
      newStudioSongs: getDataSet(songsFiltered, 'appname_studio'),
      newExpressSongs: getDataSet(songsFiltered, 'source_type_recording'),
      newStudioAndSongwriterSongs: getDataSet(songsFiltered, 'source_type_manual'),
      paidSubscriptionSongwriter: getDataSet(flagsFiltered, 'subscription_paid_songwriter'),
      trialSubscriptionSongwriter: getDataSet(flagsFiltered, 'subscription_trial_songwriter'),
      paidSubscriptionPlus: getDataSet(flagsFiltered, 'subscription_paid_plus'),
      trialSubscriptionPlus: getDataSet(flagsFiltered, 'subscription_trial_plus'),
      paidSubscriptionPro: getDataSet(flagsFiltered, 'subscription_paid_pro'),
      trialSubscriptionPro: getDataSet(flagsFiltered, 'subscription_trial_pro'),
      loginSongwriter: getDataSet(flagsFiltered, 'login_songwriter'),
      loginSongwriterWin: getDataSet(flagsFiltered, 'login_songwriter_win'),
      loginSongwriterOsx: getDataSet(flagsFiltered, 'login_songwriter_osx'),
      loginSongwriterOsxAppstore: getDataSet(flagsFiltered, 'login_songwriter_osx_appstore'),
      loginStudio: getDataSet(flagsFiltered, 'login_studio'),
      loginStudioWin: getDataSet(flagsFiltered, 'login_studio_win'),
      loginStudioOsx: getDataSet(flagsFiltered, 'login_studio_osx'),
      loginStudioOsxAppstore: getDataSet(flagsFiltered, 'login_studio_osx_appstore'),
      loginExpress: getDataSet(flagsFiltered, 'login_express'),
      loginExpressIpad: getDataSet(flagsFiltered, 'login_express_ipad'),
      loginExpressIphone: getDataSet(flagsFiltered, 'login_express_iphone'),
      loginMyScorecloud: getDataSet(flagsFiltered, 'login_my_scorecloud'),
      recordingStart: getDataSet(analyticsEventsFiltered, 'recording_start'),
      audioImport: getDataSet(analyticsEventsFiltered,'audio_import'),
      videoImport: getDataSet(analyticsEventsFiltered,'video_import'),
      songDisplay: getDataSet(analyticsEventsFiltered, 'song_display'),
      recordingPlusImport: getSumDataSet([ getDataSet(analyticsEventsFiltered, 'recording_start'), getDataSet(analyticsEventsFiltered,'audio_import'), getDataSet(analyticsEventsFiltered,'video_import') ]),
      programStartSongwriter: getDataSet(analyticsEventsFiltered, 'program_start'),
      totalUsers: getDataSet(usersFiltered, 'total_users'),
      usersMyScorecloud: getDataSet(usersFiltered, 'registration_app_myscorecloud'),
      usersStudio: getDataSet(usersFiltered, 'registration_app_studio'),
      usersExpress: getDataSet(usersFiltered, 'registration_app_express'),
      usersUnknown: getDataSet(usersFiltered, 'registration_app_unknown'),
      usersSongwriter: getDataSet(usersFiltered, 'registration_app_songwriter'),
      subscriptionStart: getDataSet(analyticsEventsFiltered, 'subscription_start'),
      subscriptionUpgrade: getDataSet(analyticsEventsFiltered, 'subscription_upgrade'),
      subscriptionDowngrade: getDataSet(analyticsEventsFiltered, 'subscription_downgrade'),
      subscriptionCancelled: getDataSet(analyticsEventsFiltered, 'subscription_cancelled'),
      subscriptionChargedSuccessfully: getDataSet(analyticsEventsFiltered, 'subscription_charged_successfully'),
      webDownloadSongwriterMac: getDataSet(analyticsEventsFiltered, 'web_download_songwriter_mac'),
      webDownloadSongwriterWin: getDataSet(analyticsEventsFiltered, 'web_download_songwriter_windows'),
      webDownloadStudioMac: getDataSet(analyticsEventsFiltered, 'web_download_studio_mac'),
      webDownloadStudioWin: getDataSet(analyticsEventsFiltered, 'web_download_studio_windows'),
      subscriptionChargedSuccessfullySum: getDataSet(analyticsEventsFiltered, 'subscription_charged_successfully_sum'),
      subscriptionChargedSuccessfullyPlusSum: getDataSet(analyticsEventsFiltered, 'subscription_charged_successfully_plus_sum'),
      subscriptionChargedSuccessfullyProSum: getDataSet(analyticsEventsFiltered, 'subscription_charged_successfully_pro_sum'),
      subscriptionChargedSuccessfullySongwriterSum: getDataSet(analyticsEventsFiltered, 'subscription_charged_successfully_songwriter_sum'),
      activeUsersStudio: getDataSet(subscribersFiltered, 'active_users_studio'),
      activeUsersSongwriter: getDataSet(subscribersFiltered, 'active_users_songwriter'),
      activeUsersExpress: getDataSet(subscribersFiltered, 'active_users_express'),
      subscribersTrialPlus: getSumDataSet([
        getDataSet(subscribersFiltered, 'subscribers_trial_plus_monthly'),
        getDataSet(subscribersFiltered, 'subscribers_trial_plus_yearly')
      ]),
      subscribersTrialSongwriter: getSumDataSet([
        getDataSet(subscribersFiltered, 'subscribers_trial_songwriter_monthly'),
        getDataSet(subscribersFiltered, 'subscribers_trial_songwriter_yearly')
      ]),
      subscribersTrialPro: getSumDataSet([
        getDataSet(subscribersFiltered, 'subscribers_trial_pro_monthly'),
        getDataSet(subscribersFiltered, 'subscribers_trial_pro_yearly')
      ]),
      subscribersPaidPlus: getSumDataSet([
        getDataSet(subscribersFiltered, 'subscribers_paid_plus_monthly'),
        getDataSet(subscribersFiltered, 'subscribers_paid_plus_yearly')
      ]),
      subscribersPaidSongwriter: getSumDataSet([
        getDataSet(subscribersFiltered, 'subscribers_paid_songwriter_monthly'),
        getDataSet(subscribersFiltered, 'subscribers_paid_songwriter_yearly')
      ]),
      subscribersPaidPro: getSumDataSet([
        getDataSet(subscribersFiltered, 'subscribers_paid_pro_monthly'),
        getDataSet(subscribersFiltered, 'subscribers_paid_pro_yearly')
      ])
    };
  }

  const getDataSetFromFilteredAndGrouped = () => {
    return {
      flagsLabels: Object.keys(flagsGrouped).map(group => group),
      songsLabels: Object.keys(songsGrouped).map(group => group),
      analyticsEventsLabels: Object.keys(analyticsEventsGrouped).map(group => group),
      usersLabels: Object.keys(usersGrouped).map(group => group),
      subscribersLabels: Object.keys(subscribersGrouped).map(group => group),
      newSongwriterSongs: getDataSetGrouped(songsGrouped, 'appname_songwriter'),
      newStudioSongs: getDataSetGrouped(songsGrouped, 'appname_studio'),
      newExpressSongs: getDataSetGrouped(songsGrouped, 'source_type_recording'),
      newStudioAndSongwriterSongs: getDataSetGrouped(songsGrouped, 'source_type_manual'),
      paidSubscriptionSongwriter: getDataSetGrouped(flagsGrouped, 'subscription_paid_songwriter'),
      trialSubscriptionSongwriter: getDataSetGrouped(flagsGrouped, 'subscription_trial_songwriter'),
      paidSubscriptionPlus: getDataSetGrouped(flagsGrouped, 'subscription_paid_plus'),
      trialSubscriptionPlus: getDataSetGrouped(flagsGrouped, 'subscription_trial_plus'),
      paidSubscriptionPro: getDataSetGrouped(flagsGrouped, 'subscription_paid_pro'),
      trialSubscriptionPro: getDataSetGrouped(flagsGrouped, 'subscription_trial_pro'),
      loginSongwriter: getDataSetGrouped(flagsGrouped, 'login_songwriter'),
      loginSongwriterWin: getDataSetGrouped(flagsGrouped, 'login_songwriter_win'),
      loginSongwriterOsx: getDataSetGrouped(flagsGrouped, 'login_songwriter_osx'),
      loginSongwriterOsxAppstore: getDataSetGrouped(flagsGrouped, 'login_songwriter_osx_appstore'),
      loginStudio: getDataSetGrouped(flagsGrouped, 'login_studio'),
      loginStudioWin: getDataSetGrouped(flagsGrouped, 'login_studio_win'),
      loginStudioOsx: getDataSetGrouped(flagsGrouped, 'login_studio_osx'),
      loginStudioOsxAppstore: getDataSetGrouped(flagsGrouped, 'login_studio_osx_appstore'),
      loginExpress: getDataSetGrouped(flagsGrouped, 'login_express'),
      loginExpressIpad: getDataSetGrouped(flagsGrouped, 'login_express_ipad'),
      loginExpressIphone: getDataSetGrouped(flagsGrouped, 'login_express_iphone'),
      loginMyScorecloud: getDataSetGrouped(flagsGrouped, 'login_my_scorecloud'),
      recordingStart: getDataSetGrouped(analyticsEventsGrouped, 'recording_start'),
      audioImport: getDataSetGrouped(analyticsEventsGrouped,'audio_import'),
      videoImport: getDataSetGrouped(analyticsEventsGrouped,'video_import'),
      songDisplay: getDataSetGrouped(analyticsEventsGrouped, 'song_display'),
      recordingPlusImport: getSumDataSet([ getDataSetGrouped(analyticsEventsGrouped, 'recording_start'), getDataSetGrouped(analyticsEventsGrouped,'audio_import'), getDataSetGrouped(analyticsEventsGrouped,'video_import') ]),
      programStartSongwriter: getDataSetGrouped(analyticsEventsGrouped, 'program_start'),
      totalUsers: getDataSetGrouped(usersGrouped, 'total_users'),
      usersMyScorecloud: getDataSetGrouped(usersGrouped, 'registration_app_myscorecloud'),
      usersStudio: getDataSetGrouped(usersGrouped, 'registration_app_studio'),
      usersExpress: getDataSetGrouped(usersGrouped, 'registration_app_express'),
      usersUnknown: getDataSetGrouped(usersGrouped, 'registration_app_unknown'),
      usersSongwriter: getDataSetGrouped(usersGrouped, 'registration_app_songwriter'),
      subscriptionStart: getDataSetGrouped(analyticsEventsGrouped, 'subscription_start'),
      subscriptionUpgrade: getDataSetGrouped(analyticsEventsGrouped, 'subscription_upgrade'),
      subscriptionDowngrade: getDataSetGrouped(analyticsEventsGrouped, 'subscription_downgrade'),
      subscriptionCancelled: getDataSetGrouped(analyticsEventsGrouped, 'subscription_cancelled'),
      subscriptionChargedSuccessfully: getDataSetGrouped(analyticsEventsGrouped, 'subscription_charged_successfully'),
      webDownloadSongwriterMac: getDataSetGrouped(analyticsEventsGrouped, 'web_download_songwriter_mac'),
      webDownloadSongwriterWin: getDataSetGrouped(analyticsEventsGrouped, 'web_download_songwriter_windows'),
      webDownloadStudioMac: getDataSetGrouped(analyticsEventsGrouped, 'web_download_studio_mac'),
      webDownloadStudioWin: getDataSetGrouped(analyticsEventsGrouped, 'web_download_studio_windows'),
      subscriptionChargedSuccessfullySum: getDataSetGrouped(analyticsEventsGrouped, 'subscription_charged_successfully_sum'),
      subscriptionChargedSuccessfullyPlusSum: getDataSetGrouped(analyticsEventsGrouped, 'subscription_charged_successfully_plus_sum'),
      subscriptionChargedSuccessfullyProSum: getDataSetGrouped(analyticsEventsGrouped, 'subscription_charged_successfully_pro_sum'),
      subscriptionChargedSuccessfullySongwriterSum: getDataSetGrouped(analyticsEventsGrouped, 'subscription_charged_successfully_songwriter_sum'),
      activeUsersStudio: getDataSetGroupedAvg(subscribersGrouped, 'active_users_studio'),
      activeUsersSongwriter: getDataSetGroupedAvg(subscribersGrouped, 'active_users_songwriter'),
      activeUsersExpress: getDataSetGroupedAvg(subscribersGrouped, 'active_users_express'),
      subscribersTrialPlus: getSumDataSet([
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_trial_plus_monthly'),
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_trial_plus_yearly')
      ]),
      subscribersTrialSongwriter: getSumDataSet([
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_trial_songwriter_monthly'),
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_trial_songwriter_yearly')
      ]),
      subscribersTrialPro: getSumDataSet([
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_trial_pro_monthly'),
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_trial_pro_yearly'),
      ]),
      subscribersPaidPlus: getSumDataSet([
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_paid_plus_monthly'),
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_paid_plus_yearly'),
      ]),
      subscribersPaidSongwriter: getSumDataSet([
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_paid_songwriter_monthly'),
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_paid_songwriter_yearly')
      ]),
      subscribersPaidPro: getSumDataSet([
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_paid_pro_monthly'),
        getDataSetGroupedAvg(subscribersGrouped, 'subscribers_paid_pro_yearly')
      ])
    };
  }

  const getData = () => {
    if (group) return getDataSetFromFilteredAndGrouped();
    return getDataSetFromFiltered();
  }

  const data = getData();

  const newSongsPerClientLabels = ['Studio', 'Songwriter', 'Express'];
  const newSongsPerClientValues = [data.newStudioSongs, data.newSongwriterSongs, data.newExpressSongs];

  const activeUsersLabels = newSongsPerClientLabels;
  const activeUsersValues = [data.activeUsersStudio, data.activeUsersSongwriter, data.activeUsersExpress];

  const subscriptionChargedSuccessfullyPlanLabels = [
    'Plus (USD)',
    'Songwriter (USD)',
    'Pro (USD)'
  ];

  const subscriptionChargedSuccessfullyPlanValues = [
    data.subscriptionChargedSuccessfullyPlusSum,
    data.subscriptionChargedSuccessfullySongwriterSum,
    data.subscriptionChargedSuccessfullyProSum
  ];

  const subscriptionChargedSuccessfullySumValues = [
    data.subscriptionChargedSuccessfullySum
  ];

  const webDownloadLabels = [
    'Songwriter Mac',
    'Songwriter Win',
    'Studio Mac',
    'Studio Win'
  ];

  const webDownloadValues = [
    data.webDownloadSongwriterMac,
    data.webDownloadSongwriterWin,
    data.webDownloadStudioMac,
    data.webDownloadStudioWin
  ];

  const newUsersLabels = [
    'My Scorecloud',
    'Studio',
    'Express',
    'Songwriter',
    'Unknown'
  ];
  const newUsersValues = [
    data.usersMyScorecloud,
    data.usersStudio,
    data.usersExpress,
    data.usersSongwriter,
    data.usersUnknown
  ];

  const newSubscriptionsLabels = [
    'Trial Plus',
    'Paid Plus',
    'Trial Songwriter',
    'Paid Songwriter',
    'Trial Pro',
    'Paid Pro'
  ];
  const newSubscriptionsValues = [
    data.trialSubscriptionPlus,
    data.paidSubscriptionPlus,
    data.trialSubscriptionSongwriter,
    data.paidSubscriptionSongwriter,
    data.trialSubscriptionPro,
    data.paidSubscriptionPro,
  ];

  const loginsStudio = [
    data.loginStudio,
    data.loginStudioOsx,
    data.loginStudioOsxAppstore,
    data.loginStudioWin
  ];

  const loginsSongwriter = [
    data.loginSongwriter,
    data.loginSongwriterOsx,
    data.loginSongwriterOsxAppstore,
    data.loginSongwriterWin
  ];

  const loginsExpress = [
    data.loginExpress,
    data.loginExpressIpad,
    data.loginExpressIphone
  ];

  const expectedExpressRevenueLabels = [
    'Unknown (USD)',
    'iPad (USD)',
    'iPhone (USD)'
  ];
  const expectedExpressRevenue = [];
  const expectedExpressRevenueIpad = [];
  const expectedExpressRevenueIphone = [];
  for (let i=0; i < data.loginExpressIpad.length; i++) {
    expectedExpressRevenue[i] = Math.round(data.loginExpress[i] * 2.99);
    expectedExpressRevenueIpad[i] = Math.round(data.loginExpressIpad[i] * 2.99);
    expectedExpressRevenueIphone[i] = Math.round(data.loginExpressIphone[i] * 2.99);
  }
  const expectedExpressRevenueValues = [
    expectedExpressRevenue,
    expectedExpressRevenueIpad,
    expectedExpressRevenueIphone
  ];

  const subscribersLabelsPaid = [
    'Paid Plus',
    'Paid Songwriter',
    'Paid Pro'
  ];

  const subscribersValuesPaid = [
    data.subscribersPaidPlus,
    data.subscribersPaidSongwriter,
    data.subscribersPaidPro
  ];

  const subscribersLabelsTrial = [
    'Trial Plus',
    'Trial Songwriter',
    'Trial Pro'
  ];

  const subscribersValuesTrial = [
    data.subscribersTrialPlus,
    data.subscribersTrialSongwriter,
    data.subscribersTrialPro
  ];

  return (
    <Page
      className='DashboardPage'
      title='Dashboard'
      breadcrumbs={[{ name: title, active: true }]}
    >
      <Row>
        <Col xl={12} lg={12} md={12}>
          <ChartCard
            header='User Registration per Client (Stacked)'
            loading={usersPerDay.loading}
            error={errors.usersPerDay}
            values={newUsersValues}
            height="350px"
            description="Number of new registered users per registration client"
          >
            <Line
              data={chartConfig(
                data.usersLabels,
                newUsersLabels,
                newUsersValues,
                [
                  colors.variant_lightgreen,
                  colors.variant_lightblue,
                  colors.variant_lightred,
                  colors.variant_lightyellow,
                  colors.variant_lightpurple
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED, undefined, CUSTOMHEIGHT)}
            />
          </ChartCard>
        </Col>
        <Col xl={12} lg={12} md={12}>
          <ChartCard
            header='New Subscriptions (Stacked)'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={newSubscriptionsValues}
            height="350px"
            description="Number of users starting a specific subscription for the first time (same user can appear in multiple, re-subbing to same plan not included)"
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                newSubscriptionsLabels,
                newSubscriptionsValues,
                [
                  colors.variant_lightgreen,
                  colors.calm_darkgreen,
                  colors.variant_lightyellow,
                  colors.calm_darkyellow,
                  colors.variant_lightpurple,
                  colors.calm_darkpurple
                ],
                FILL,
                STACKED)}
                options={setOptions(STACKED, undefined, CUSTOMHEIGHT)}
            />
          </ChartCard>
        </Col>
        <Col xl={12} lg={12} md={12}>
          <ChartCard
            header='Revenue from Successfully charged subscriptions'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={subscriptionChargedSuccessfullySumValues}
            height="350px"
            description="Sum of money paid to Doremir from ScoreCloud subscriptions (in USD from Braintree)"
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                ['Earnings (USD)'],
                [data.subscriptionChargedSuccessfullySum],
                [colors.darkgreen])}
              options={setOptions(undefined, undefined, CUSTOMHEIGHT)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='30 Day Active Users (Stacked)'
            loading={subscribersPerDay.loading}
            error={errors.subscribersPerDay}
            values={activeUsersValues}
            hideTotal={true}
            description="Number of users active last 30 days"
          >
            <Line
              data={chartConfig(
                data.subscribersLabels,
                activeUsersLabels,
                activeUsersValues,
                [
                  colors.variant_lightblue,
                  colors.variant_lightyellow,
                  colors.variant_lightred,
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Number of Subscribers Paid'
            loading={subscribersPerDay.loading}
            error={errors.subscribersPerDay}
            values={subscribersValuesPaid}
            hideTotal={true}
            description="Snapshot of active paid subscriptions"
          >
            <Line
              data={chartConfig(
                data.subscribersLabels,
                subscribersLabelsPaid,
                subscribersValuesPaid,
                [
                  colors.teal,
                  colors.yellow,
                  colors.purple
                ]
              )}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Revenue from successfully charged subscriptions (Stacked)'
            description="Sum of money paid to Doremir from ScoreCloud subscriptions per plan (in USD from Braintree)"
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={subscriptionChargedSuccessfullyPlanValues}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                subscriptionChargedSuccessfullyPlanLabels,
                subscriptionChargedSuccessfullyPlanValues,
                [
                  colors.calm_darkgreen,
                  colors.calm_darkyellow,
                  colors.calm_darkpurple
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Estimated Revenue From ScoreCloud Express Installs'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={expectedExpressRevenueValues}
            description="Number of users doing their first login in ScoreCloud Express × App Price ($2.99). Unknowns are social logins where we can't see OS here."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                expectedExpressRevenueLabels,
                expectedExpressRevenueValues,
                [colors.lightblue, colors.blue, colors.darkblue],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Number of Subscribers Trial'
            loading={subscribersPerDay.loading}
            error={errors.subscribersPerDay}
            values={subscribersValuesPaid}
            hideTotal={true}
            description="Snapshot of active trial subscriptions"
          >
            <Line
              data={chartConfig(
                data.subscribersLabels,
                subscribersLabelsTrial,
                subscribersValuesTrial,
                [
                  colors.teal,
                  colors.yellow,
                  colors.purple
                ]
              )}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Songs Per Client (Stacked)'
            loading={songsPerDay.loading}
            error={errors.songsPerDay}
            values={newSongsPerClientValues}
            description="Number of new songs saved on the server (locally not counted)"
          >
            <Line
              data={chartConfig(
                data.songsLabels,
                newSongsPerClientLabels,
                newSongsPerClientValues,
                [colors.darkteal, colors.darkyellow, colors.lightred],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Songwriter Songs Saved'
            loading={songsPerDay.loading}
            error={errors.songsPerDay}
            values={[data.newSongwriterSongs]}
            description="Number of new songs saved on the server (locally not counted)"
          >
            <Line
              data={chartConfig(
                data.songsLabels,
                [SONGS_COUNT_CHART_LABEL],
                [data.newSongwriterSongs],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Studio Songs Saved'
            loading={songsPerDay.loading}
            error={errors.songsPerDay}
            values={[data.newStudioSongs]}
            description="Number of new songs saved on the server (locally not counted)"
          >
            <Line
              data={chartConfig(
                data.songsLabels,
                [SONGS_COUNT_CHART_LABEL],
                [data.newStudioSongs],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Studio and Songwriter Songs Saved'
            loading={songsPerDay.loading}
            error={errors.songsPerDay}
            values={[data.newStudioAndSongwriterSongs]}
            description="Number of new songs saved on the server (locally not counted)"
          >
            <Line
              data={chartConfig(
                data.songsLabels,
                [SONGS_COUNT_CHART_LABEL],
                [data.newStudioAndSongwriterSongs],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Express Songs Saved'
            loading={songsPerDay.loading}
            error={errors.songsPerDay}
            values={[data.newExpressSongs]}
            description="Number of new songs saved on the server (locally not counted)"
          >
            <Line
              data={chartConfig(
                data.songsLabels,
                [SONGS_COUNT_CHART_LABEL],
                [data.newExpressSongs],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time Plus Trial Subscriptions'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.trialSubscriptionPlus]}
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.trialSubscriptionPlus],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time Plus Paid Subscriptions'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.paidSubscriptionPlus]}
            description="Number of users getting this plan for the first time. They might have had other plans before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.paidSubscriptionPlus],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time Songwriter Trial Subscriptions'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.trialSubscriptionSongwriter]}
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.trialSubscriptionSongwriter],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time Songwriter Paid Subscriptions'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.paidSubscriptionSongwriter]}
            description="Number of users getting this plan for the first time. They might have had other plans before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.paidSubscriptionSongwriter],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time Pro Trial Subscriptions'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.trialSubscriptionPro]}
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.trialSubscriptionPro],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time Pro Paid Subscriptions'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.paidSubscriptionPro]}
            description="Number of users getting this plan for the first time. They might have had other plans before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.paidSubscriptionPro],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time login Studio (stacked)'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={loginsStudio}
            description="Number of users logging in to this client for the first time. They might have used other clients before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [
                  'Studio',
                  'Studio Osx',
                  'Studio Osx Appstore',
                  'Studio Win'
                ],
                loginsStudio,
                [
                  colors.blue,
                  colors.darkblue,
                  colors.darkgreen,
                  colors.orange
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time login Songwriter (stacked)'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={loginsSongwriter}
            description="Number of users logging in to this client for the first time. They might have used other clients before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [
                  'Songwriter',
                  'Songwriter Osx',
                  'Songwriter Osx Appstore',
                  'Songwriter Win'
                ],
                loginsSongwriter,
                [
                  colors.darkyellow,
                  colors.yellow,
                  colors.purple,
                  colors.secondary
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time login Express (stacked)'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={loginsExpress}
            description="Number of users logging in to this client for the first time. They might have used other clients before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                [
                  'Express',
                  'Express Ipad',
                  'Express Iphone'
                ],
                loginsExpress,
                [
                  colors.yellow,
                  colors.purple,
                  colors.lightred
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='First time login My Scorecloud'
            loading={flagsPerDay.loading}
            error={errors.flagsPerDay}
            values={[data.loginMyScorecloud]}
            description="Number of users logging in to this client for the first time. They might have used other clients before."
          >
            <Line
              data={chartConfig(
                data.flagsLabels,
                ['My Scorecloud'],
                [data.loginMyScorecloud],
                [colors.teal],
                FILL,
                STACKED)}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter recordings'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.recordingStart]}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                ['Recordings'],
                [data.recordingStart],
                [colors.orange])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter audio imports'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.audioImport]}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                ['Audio imports'],
                [data.audioImport],
                [colors.darkblue])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter video imports'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.videoImport]}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                ['Video imports'],
                [data.videoImport],
                [colors.darkred])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter analysis started events'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.recordingPlusImport]}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                ['Started'],
                [data.recordingPlusImport],
                [colors.purple])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter finished events'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.songDisplay]}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                ['Finished'],
                [data.songDisplay],
                [colors.green])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Songwriter app starts'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.programStartSongwriter]}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                [APP_STARTS_COUNT_CHART_LABEL],
                [data.programStartSongwriter],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='New Registered User Accounts'
            loading={usersPerDay.loading}
            error={errors.usersPerDay}
            values={[data.totalUsers]}
          >
            <Line
              data={chartConfig(
                data.usersLabels,
                ['Users'],
                [data.totalUsers],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Subscription start'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.subscriptionStart]}
            description="Number of subscriptions started. They might have used other subscriptions before."
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.subscriptionStart],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Subscription charged successfully'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.subscriptionChargedSuccessfully]}
            description="Number of successful payments, new and recurring"
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.subscriptionChargedSuccessfully],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Subscription upgrade'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.subscriptionUpgrade]}
            description="Number of subscriptions upgraded to a more expensive plan"
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.subscriptionUpgrade],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Subscription downgrade'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.subscriptionDowngrade]}
            description="Number of subscriptions downgraded to a less expensive paid plan (not FREE)"
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.subscriptionDowngrade],
                [colors.secondary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Subscription canceled'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={[data.subscriptionCancelled]}
            description="Number of subscriptions downgraded to FREE"
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                [SUBSCRIPTION_COUNT_CHART_LABEL],
                [data.subscriptionCancelled],
                [colors.primary])}
              options={setOptions()}
            />
          </ChartCard>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <ChartCard
            header='Web Downloads (Stacked)'
            loading={analyticsEventsPerDay.loading}
            error={errors.analyticsEventsPerDay}
            values={webDownloadValues}
          >
            <Line
              data={chartConfig(
                data.analyticsEventsLabels,
                webDownloadLabels,
                webDownloadValues,
                [
                  colors.yellow,
                  colors.darkyellow,
                  colors.teal,
                  colors.darkteal
                ],
                FILL,
                STACKED)}
              options={setOptions(STACKED)}
            />
          </ChartCard>
        </Col>
      </Row>
    </Page>
  );
}

export default DashboardFilteredAndGrouped;
